import styled from 'styled-components';

export const AboutContainer = styled.div`
	width: 100%;
	min-height: 700px;
	justify-content: center;
	display: flex;
	@media screen and (max-width: 768px) {
		padding: 0 20px;
	}
	@media screen and (max-width: 1440px) {
		min-height: unset;
	}
`;

export const AboutWrapper = styled.div`
	display: flex;
	padding: 7rem 7rem 0rem 7rem;
	justify-content: center;
	align-items: center;
	@media screen and (max-width: 768px) {
		padding: 7rem 20px 0 20px;
	}
	@media screen and (max-width: 1440px) {
		padding: 7rem 20px 0 20px;
	}
`;

export const Title = styled.h1`
	font-weight: 900;
	font-weight: bold;
	font-size: 36px;
	line-height: 49px;
	color: #01768a;
	@media screen and (max-width: 768px) {
		display: flex;
		justify-content: center;
		padding-top: 2rem;
	}
`;

export const Subtitle = styled.h5`
	font-weight: 300;
	text-transform: uppercase;
	font-size: 36px;
	color: #000;
	line-height: 49px;
`;
