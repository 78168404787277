import styled from 'styled-components';

export const InfoContainer = styled.div`
	width: 100%;
	// min-height: 700px;
	justify-content: center;
	display: flex;
	@media screen and (max-width: 768px) {
		padding: 0 20px;
	}
	@media screen and (max-width: 1440px) {
		min-height: unset;
	}
`;

export const InfoWrapper = styled.div`
	display: flex;
	padding: 80px 0 0 0;
	justify-content: center;
	align-items: center;
	@media screen and (max-width: 499px) {
		padding: 20px 20px 0 20px !important;
	}
	@media screen and (max-width: 1440px) {
		padding: 80px 20px 0 20px;
	}
`;

export const Title = styled.h1`
	font-weight: 900;
	font-weight: bold;
	font-size: 36px;
	line-height: 49px;
	color: #001e23;
`;

export const Subtitle = styled.h5`
	font-weight: 300;
	text-transform: uppercase;
	font-size: 36px;
	color: #334b4f;
	line-height: 49px;
`;
