import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import { AboutContainer, AboutWrapper, Title } from './aboutUsElements';

export const AboutSection = ({ id, title, text, image }) => {
	return (
		<>
			<AboutContainer id={id}>
				<AboutWrapper>
					<div className='row expand-row gx-5'>
						<div className='col-lg-6 col-sm-12 img-wrap'>
							<img src={image} alt='' className='fit-img'></img>
						</div>
						<div className='col-lg-6 col-sm-12 my-auto'>
							<Title className='mb-5'>{title}</Title>
							<p className='info-text mb-4'>{text}</p>
						</div>
					</div>
				</AboutWrapper>
			</AboutContainer>
		</>
	);
};

export default AboutSection;
