import styled from 'styled-components';

import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';

export const FooterContainer = styled.div`
	padding: 50px 0 50px 0;
	background: #001e23;
	border-radius: 40px 40px 0px 0px;
`;

export const FooterWrapper = styled.div``;

export const FooterH2 = styled.h2`
	font-size: 1.3rem;
	color: white;
	font-weight: 500;
	margin-bottom: 30px;
`;

export const FooterLink = styled.a`
	font-size: 0.9rem;
	text-decoration: none;
	color: white;
	display: flex;
	transition: all 0.3s;

	&:hover {
		color: #b05ffd;
	}
`;

export const Nav = styled.nav`
	height: 80px;
	display: flex;
	align-items: center;
	font-size: 1rem;
	position: sticky;
	background-color: #fff;
	top: 0;
	z-index: 10;
	transition: all 0.3s ease-in-out;
	-webkit-box-shadow: 0 10px 5px -11px #777;
	-moz-box-shadow: 0 10px 5px -11px #777;
	box-shadow: 0 10px 5px -11px #777;

	@media screen and (max-width: 960px) {
		transition: 0.8s all ease;
	}
`;
export const NavbarContainer = styled.div`
	display: flex;
	justify-content: space-between;
	height: 80px;
	z-index: 1;
	width: 100%;
	padding: 0 24px;
`;
export const NavLogo = styled(LinkR)`
	color: #000000;
	justify-self: flex-start;
	cursor: pointer;
	font-size: 1.5rem;
	align-items: center;
	margin-left: 24px;
	font-weight: bold;
	text-decoration: none;
	transition: all ease 0.2s;
`;

export const HomeLogo = styled.img`
	height: 59px;
	width: 64px;
	transition: all ease 0.2s;
`;

export const MobileIcon = styled.div`
	display: none;

	@media screen and (max-width: 768px) {
		display: block;
		//position: absolute;
		//top: 0;
		//right: 0;
		margin-top: auto;
		margin-bottom: auto;
		//transform: translate(-100%, 60%);
		font-size: 1.8rem;
		cursor: pointer;
		color: #fff;
	}
`;

export const NavMenu = styled.ul`
	display: flex;
	align-items: center;
	list-style: none;
	text-align: center;
	height: 80px;
	justify-content: space-between;

	@media screen and (max-width: 768px) {
		display: none;
	}
`;

export const NavItem = styled.li`
	height: 80px;
`;

export const NavLinks = styled(LinkS)`
	color: #fdfdfd;
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 0 1rem;
	height: 100%;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	font-size: 14px;
	font-weight: 600;

	&.active {
		border-bottom: 3px solid #b05ffd;
	}
`;
export const NavBtn = styled.nav`
	display: flex;
	align-items: center;
	@media screen and (max-width: 768px) {
		display: none;
	}
`;

export const ServicesIcon = styled.img`
	height: 13px;
	width: 16px;
	position: relative;
	top: 0.5rem;
`;

export const SocialMediaIcon = styled.img`
	height: 24px;
	width: 24px;
	margin-right: 1.5rem;
`;

export const NavBtnLink = styled(LinkR)`
	border-radius: 50px;
	text-decoration: none;
	background: #b05ffd;
	white-space: nowrap;
	padding: 10px 22px;
	color: #010606;
	font-size: 1rem;
	outline: none;
	border: none;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	font-weight: 900;

	&:hover {
		transition: all 0.2s ease-in-out;
		background: #fff;
		color: #b05ffd;
	}
`;
