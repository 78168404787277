import React from 'react';
import { FooterContainer, FooterWrapper } from './FooterElements';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
	NavMenu,
	NavItem,
	NavLinks,
	HomeLogo,
	NavLogo,
} from './FooterElements';
import HomeIcon from '../../images/home-logo.svg';
import './../../App.css';
import { ServicesIcon, SocialMediaIcon } from './FooterElements';
import Icon1 from '../../images/footer-email.svg';
import Icon2 from '../../images/footer-phone.svg';
import Icon4 from '../../images/footer-linkedin.svg';

const Footer = () => {
	return (
		<FooterContainer>
			<FooterWrapper className=''>
				<div className='logo-wrapper'>
					<NavLogo to='/'>
						<HomeLogo src={HomeIcon} />
						<p className='font-white logo-text'>
							Business growth consulting firm
						</p>
					</NavLogo>
				</div>
				<NavMenu>
					<div className='footer-items-wrapper'>
						<NavItem>
							<NavLinks to='about' className='footer-link'>
								About Us
							</NavLinks>
						</NavItem>
						<NavItem>
							<NavLinks to='proposition' className='footer-link'>
								Our Value Proposition
							</NavLinks>
						</NavItem>
						<NavItem>
							<NavLinks to='solutions' className='footer-link'>
								Our Growth-led Solutions
							</NavLinks>
						</NavItem>
						<NavItem>
							<NavLinks to='onboard' className='footer-link'>
								When to Onboard Us
							</NavLinks>
						</NavItem>
						<NavItem>
							<NavLinks to='contact' className='footer-link'>
								Reach Us
							</NavLinks>
						</NavItem>
					</div>
					<div className='footer-contacts-wrapper'>
						<div className=''>
							<div className='email-wrapper'>
								<ServicesIcon src={Icon1} />
								<p className='opus-email m-0 white-text pl-1'>
									growth@opusevolve.com
								</p>
							</div>

							<div className='phone-wrapper p-0'>
								<ServicesIcon src={Icon2} />
								<p className='opus-phone m-0 white-text pl-1'>+91 9819170999</p>
							</div>
						</div>
						<div className='social-media-wrapper'>
							<a href='https://www.linkedin.com/in/milanthakkar?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BooIPHaoCQPW1tgs7VK5Wlg%3D%3D' 
							rel='noopener noreferrer' target='_blank'>
								<SocialMediaIcon src={Icon4} />
							</a>
						</div>
					</div>
				</NavMenu>
				<div className='copy-right-wrapper'>
					<p>Copyright 2022. Opusevolve All rights reserved.</p>
					<p>Design & Developed by GotoApp Solutions Pvt Ltd.</p>
				</div>
			</FooterWrapper>
		</FooterContainer>
	);
};

export default Footer;
