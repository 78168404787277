import React from 'react';
import {
	SigninContainer,
	SigninWrapper,
	SigninInput,
	SigninNumber,
	LocationTrackerImage,
} from './ReachUsElements';
import { Button } from '../ButtonElements';
import Icon2 from '..//../images/location-track-email.svg';
import Icon3 from '..//../images/location-track-phone.svg';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import emailjs from 'emailjs-com';

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}));

const companyIndustryOptions = [
	'Accounting',
	'Architecture',
	'Commercial Real Estate',
	'Communications',
	'Consulting',
	'Construction',
	'Education & Training',
	'Engineering/Environmental',
	'Financial Services',
	'Government Contracting',
	'Healthcare',
	'Hospitality/Foodservice',
	'Industrial & Manufacturing',
	'Legal',
	'Logistics & Transportation',
	'Media',
	'Professional Services',
	'Staffing/Talent/HR',
	'Technology',
	'Other',
];

const ReachUs = ({ id }) => {
	const classes = useStyles();
	const [inputValue, setInputValue] = React.useState({
		fullname: '',
		email: '',
		organization: '',
		phone: null,
		domain: '',
	});

	const templateParams = {
		fullname: inputValue.fullname,
		email: inputValue.email,
		organization: inputValue.organization,
		phone: inputValue.phone,
		domain: inputValue.domain,
	};

	const validateEmail = (email) => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	};

	function validatePhone(phone) {
		const regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
		return regex.test(phone)
	  }

	const sendEmail = () => {
		if(validateEmail(inputValue.email) && validatePhone(inputValue.phone)){
			emailjs
			.send(
				'service_raqiuo7',
				'template_7hsnf4b',
				templateParams,
				'4hzNJm7DHDBUQmWhx'
			)
			.then(
				(response) => {
					console.log('SUCCESS!', response.status, response.text);
				},
				(err) => {
					console.log('FAILED...', err);
				}
			);
		} else {
			alert("Please enter Valid email or phone number" )
		}
		
		if (
			inputValue.fullname !== '' &&
			inputValue.email !== '' &&
			inputValue.phone !== null 
		) {
			alert('Thank you, Email Sent Successfully');
		}
		onClear();
	};

	const onClear = () => {
		setInputValue({
			fullname: '',
			email: '',
			organization: '',
			phone: '',
			domain: '',
		});
	};

	const onChangeEvent = (e) => {
		const value = e.target.value;
		setInputValue({
			...inputValue,
			[e.target.name]: value,
		});
	};
	return (
		<>
			<div className='reach-us-parent-wrapper' id={id}>
				<Typography className='reach-us-heading'>Reach Us!</Typography>
				<div className='reach-us-wrapper'>
					<div className='row expand-row gx-5'>
						<div className='col-lg-6 col-sm-12 my-auto'>
							<SigninContainer>
								<SigninWrapper className='py-5'>
									<div className='my-auto'>
										<p className='enquiry-text mb-5'>
											Send an Enquiry!
											<span className='enquiry-info'>
												We will get back to you soon...
											</span>
										</p>

										<form>
											<SigninInput
												type='text'
												placeholder='Full Name *'
												className='enquiry-form'
												name='fullname'
												value={inputValue.fullname}
												onChange={onChangeEvent}
											/>
											<SigninInput
												type='text'
												placeholder='Email *'
												className='enquiry-form'
												value={inputValue.email}
												name='email'
												onChange={onChangeEvent}
											/>
											<SigninInput
												type='text'
												placeholder='Organization'
												className='enquiry-form'
												name='organization'
												value={inputValue.organization}
												onChange={onChangeEvent}
											/>
											<SigninNumber
												type='number'
												placeholder='Phone *'
												className='enquiry-form'
												name='phone'
												value={inputValue.phone}
												onChange={onChangeEvent}
											/>
											{/* <CompanyIndustryDropdown /> */}
											<div>
												<FormControl className={classes.formControl}>
													<Select
														value={inputValue.domain}
														name='domain'
														onChange={onChangeEvent}
														displayEmpty
														className={classes.selectEmpty}
														inputProps={{ 'aria-label': 'Without label' }}
													>
														<MenuItem value='' disabled>
															Domain
														</MenuItem>
														{companyIndustryOptions.map((option) => {
															return (
																<MenuItem value={option}>{option}</MenuItem>
															);
														})}
													</Select>
												</FormControl>
											</div>
											<p className='form-hint'>
												All Fields marked with * are mandatory
											</p>
											<Button
												className='mb-3 mt-2 sumbit-button'
												onClick={inputValue.fullname === '' &&
												inputValue.email === '' &&
												inputValue.phone === null ? undefined : sendEmail}
											>
												SUBMIT
											</Button>
										</form>
									</div>
								</SigninWrapper>
							</SigninContainer>
						</div>
						<div className='seperator col-lg-1 col-sm-12'></div>
						<div className='col-lg-4 col-sm-12 img-wrap'>
							<div className='contact-us-location-wrapper'>
								<div className='location-tracker-email d-flex'>
									<LocationTrackerImage src={Icon2} className='pr-1' />
									<p className='location-email-text-wrapper mb-4'>
										growth@opusevolve.com
									</p>
								</div>
								<div className='location-tracker-phone d-flex'>
									<LocationTrackerImage src={Icon3} className='pr-1' />
									<p className='location-phone-text-wrapper mb-4'>
										+91 9819170999
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ReachUs;
