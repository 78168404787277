import styled from 'styled-components';

export const SigninContainer = styled.div`
	align-content: center;
	justify-content: center;
	display: flex;
	position: relative;
`;

export const SigninWrapper = styled.div`
	min-height: 600px;
	width: 85%;
	max-width: 90%;
	margin: auto;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	@media screen and (max-width: 499px) {
		width: 100%;
		max-width: 100%;
		padding-left: 1.4rem;
	}
`;

export const SigninP = styled.p`
	font-weight: 600;
	margin-bottom: 5px;
`;

export const SigninInput = styled.input`
	width: 100%;
	padding: 16px 24px;
	background: #ffffff;
	box-sizing: border-box;
	border-radius: 12px;
	margin-bottom: 10px;
	border: 0;

	&:focus-visible {
		outline: #01768a auto 1px;
	}
`;

export const SigninNumber = styled.input.attrs({ maxLength: 10 })`
	width: 100%;
	padding: 16px 24px;
	background: #ffffff;
	box-sizing: border-box;
	border-radius: 12px;
	margin-bottom: 10px;
	border: 0;

	&:focus-visible {
		outline: #01768a auto 1px;
	}
`;

export const LocationTrackerImage = styled.img`
	height: 24px;
	width: 24px;
`;
