import React from 'react';
import './../../App.css';
import { ServicesIcon, SocialMediaIcon } from './topNavBarElements';
import Icon1 from '../../images/email.svg';
import Icon2 from '../../images/phone.svg';
import Icon4 from '../../images/linkedin.svg';

export const TopNavBar = ({ toggle }) => {
	return (
		<>
			<div className='top-navbar'>
				<div className='contacts-wrapper'>
					<ServicesIcon src={Icon1} />
					<p className='opus-email m-0'>growth@opusevolve.com</p>
					<div className='phone-wrapper'>
						<ServicesIcon src={Icon2} />
						<p className='opus-phone m-0'>+91 9819170999</p>
					</div>
				</div>
				<div className='social-media-wrapper'>
					<a href='https://www.linkedin.com/in/milanthakkar?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BooIPHaoCQPW1tgs7VK5Wlg%3D%3D' 
					rel='noopener noreferrer' target='_blank'>
						<SocialMediaIcon src={Icon4} />
					</a>
				</div>
			</div>
		</>
	);
};
