import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: '33.33%',
		flexShrink: 0,
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
	},
}));

export default function OurValuePropositions({ id }) {
	const classes = useStyles();
	const [expanded, setExpanded] = React.useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<>
			<div className='our-propositions-parent-wrapper' id={id}>
				<div className='our-propositions-heding'>
					<Typography className='proposition-heading'>
						Our Value Proposition!
					</Typography>
				</div>

				<div className='our-propositions-wrapper'>
					<div className={classes.root}>
						<Accordion
							expanded={expanded === 'panel1'}
							onChange={handleChange('panel1')}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls='panel1bh-content'
								id='panel1bh-header'
							>
								<Typography className={classes.heading}>
									1. Our Trademark MATCH to MARKET ™ suite of services
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography>
									Our M2M module encapsulates services that we have
									custom-rolled for our global and domestic clientele. A deep
									dive into our capabilities quickly demonstrates that we offer
									a suite of services one would normally associate with a sales
									training firm, a marketing agency, a strategy consultant or a
									leadership development company
								</Typography>
							</AccordionDetails>
							<AccordionDetails>
								<Typography>
									The first stage of our bi-party engagement begins with the Business 
									Growth Assessment where we require our clients to complete a structured 
									planning process. The assessment phase ranges from 30 to 90 days depending 
									on the size and complexity of the enterprise, and provides a comprehensive 
									roadmap for all other decisions.
								</Typography>
							</AccordionDetails>
						</Accordion>
						<Accordion
							expanded={expanded === 'panel2'}
							onChange={handleChange('panel2')}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls='panel2bh-content'
								id='panel2bh-header'
							>
								<Typography className={classes.secondaryHeading}>
									2. Balance sheet friendly M2M ™ plan
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography>
									In short, we give CEOs a concrete list of Do’s and Don’ts to
									make their businesses lean agile and competitive. This Match
									to Market advice is a proven process which eliminates ad-hoc
									decisions and unforeseen losses which hurt the balance sheet.
								</Typography>
							</AccordionDetails>
							<AccordionDetails>
								<Typography>
									The company changes gears to align with the new strategic direction.
									It also ensures that clients get to know us and we get to know them -
									well enough to understand the unique culture, personalities and issues 
									at the core of their business.
								</Typography>
							</AccordionDetails>
						</Accordion>
						<Accordion
							expanded={expanded === 'panel3'}
							onChange={handleChange('panel3')}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls='panel3bh-content'
								id='panel3bh-header'
							>
								<Typography className={classes.secondaryHeading}>
									3. Challenge Navigation: Our Forte
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography>
									We recognize that most problems with business performance
									(i.e. increasing revenues, improving profitability, enhancing
									growth or preparing for exit) are due to one or more of the
									following causes:
									<ul className='proposition-list'>
										<li>
											The CEO has not clearly defined the business strategy or
											direction. There is a well-defined strategy on paper, but
											remains largely un- implemented.
										</li>
										<li>
											Internal assumption and sycophancy rule the roost without
											external validation or a Match to Market strategy.
										</li>
										<li>
											Penny wise pound foolish syndrome takes over employees who
											lose sight of the larger organizational goals.
										</li>
										<li>
											There is no clear synergy across key
											departments, most notably between sales and marketing.
										</li>
									</ul>
								</Typography>
							</AccordionDetails>
							<AccordionDetails>
								<Typography>
									The only way to overcome all of the above problems is to
									develop an actionable{' '}
									<span className='proposition-font'>Match to Market™</span>{' '}
									strategy, assess it against the realities of the marketplace,
									and then align sales, marketing, strategy and leadership{' '}
									<span className='proposition-font'>
										so that the organizational goals can be achieved.
									</span>
								</Typography>
							</AccordionDetails>
						</Accordion>

						<div className='video-wrapper'>
							<div className='our-propositions-heding'>
								<Typography className='proposition-heading'>
									Milan Thakkar at Earlier Avatar
								</Typography>
							</div>
							<iframe
								width='988'
								height='494'
								src='https://www.youtube.com/embed/YVExP_DhJkE'
								title='YouTube video player'
								frameborder='0'
								allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
								allowfullscreen
							></iframe>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
