import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import { InfoContainer, InfoWrapper, Subtitle, Title } from './InfoElements';
import { ButtonBasic } from '../ButtonElements';

export const InfoSection = ({ id, title, subtitle, text, image, btnText }) => {
	const [scrollNav, setScrollNav] = useState(false);

	const changeNav = () => {
		if (window.scrollY >= 80) {
			setScrollNav(true);
		} else {
			setScrollNav(false);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', changeNav);
	}, []);
	return (
		<>
			<InfoContainer id={id}>
				<InfoWrapper>
					<div className='row expand-row gx-5'>
						<div className='col-lg-6 col-sm-12 my-auto'>
							<Subtitle>{subtitle}</Subtitle>
							<Title className='mb-5'>{title}</Title>
							<p className='info-text mb-4'>{text}</p>
							<ButtonBasic
								to='contact'
								primary='true'
								dark='true'
								className='mx-auto expert-button'
								scrollNav={scrollNav}
							>
								{btnText}
							</ButtonBasic>
						</div>
						<div className='col-lg-6 col-sm-12 img-wrap'>
							<img src={image} alt='' className='fit-img'></img>
						</div>
					</div>
				</InfoWrapper>
			</InfoContainer>
		</>
	);
};

export default InfoSection;
