import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import {
	InfoContainer,
	InfoWrapper,
	Subtitle,
	Title,
	Heading,
} from './infoElements';

export const InfoSectionLight = ({
	id,
	title,
	subtitle,
	text,
	image,
	isOnboard,
	heading,
}) => {
	return (
		<>
			<div className={isOnboard ? 'onboard-parent-wrapper' : ''}>
				<InfoContainer
					id={id}
					className={!isOnboard ? 'bg-white not-onboard' : 'bg-white on-board'}
				>
					<InfoWrapper className={isOnboard ? 'not-onboard' : ''}>
						<div className='row expand-row gx-5'>
							<div className='col-lg-6 col-sm-12 my-auto'>
								<Heading className='mb-4'>{heading}</Heading>
								<Subtitle>{subtitle}</Subtitle>
								<Title className='our-growth-text mb-4'>{title}</Title>
								<p className='our-growth-paragraph mb-4'>{text}</p>
								{isOnboard ? (
									<div className='growth-led-solutions-list-wrapper'>
										<ul className='list-wrapper'>
											<li>
												You are the MD/CEO or owner of a privately-held
												business-to-business (B2B) or (B2C) company
											</li>
											<li>
												Strategic decisions and investments are made by a CEO,
												sole or majority owner, or a designated managing partner
												(not by a board of directors or partner committee).
											</li>
											<li>
												Sales are generated through an in-house employee sales
												team, defined sales channels, or through direct outreach
												by your professional services leadership (i.e.
												sell/manage/deliver).
											</li>
											<li>
												You desire to grow the business on a strategic
												differentiation model, and not on a commodity offering
												or pure trading model.
											</li>
											<li>
												The business itself is intended to be the primary asset
												whose value is at the core of the owner's exit strategy
												(i.e. investing in the business is investing in the exit
												strategy).The owner's anticipated exit point or
												strategic sale is at least 3-5 years in the future.
											</li>
											<li>
												The business is an emerging growth company or a
												middle-market enterprise. Your company's annual revenues
												are in the range of $2M to $25M or higher.
											</li>
											<li>
												Achieving new revenue/profit/growth is a strategic
												priority you are willing to invest in this year.
											</li>
											<li>
												{' '}
												There are clear opportunities to improve results in
												sales, marketing, strategy or leadership. Budgets are
												set aside for investment in business growth (typically
												10-20% of revenue).
											</li>
										</ul>
									</div>
								) : (
									<div className='growth-led-solutions-list-wrapper'>
										<ul className='list-wrapper'>
											<li>
												Repositioning the firm's Match to market™ strategy
											</li>
											<li>Service Segmentation & defining newer verticals</li>
											<li>Launching new products or services</li>
											<li>
												Business Diversification process Business Risk
												Mitigation process
											</li>
											<li>
												Change Management owing to Changes in ownership or
												executive leadership
											</li>
											<li>Pursuing a corporate rebranding</li>
											<li>Strategic planning for increased exit valuation</li>
											<li> Merger or acquisition of a new company</li>
											<li>Startup of a new venture</li>
										</ul>
									</div>
								)}
							</div>
							<div
								className={
									isOnboard
										? 'col-lg-5 col-sm-12 img-wrap our-growth-image'
										: 'col-lg-6 col-sm-12 img-wrap our-growth-image'
								}
							>
								<img
									src={image}
									alt=''
									className={isOnboard ? 'fit-img onboard-image' : 'fit-img'}
								></img>
							</div>
							{!isOnboard ? (
								<div className='our-growth-hint-wrapper'>
									<p className=''>
										Each of these examples represents a significant period of
										planned transition in the business lifecyle, offering the
										right opportunity to improve the company's strategic
										direction and prepare for growth.
									</p>
								</div>
							) : (
								''
							)}
							{!isOnboard ? (
								<div className='our-customer-reservoir'>
									<div className='our-customer-reservoir-inner-wrapper'>
										<h2 className='mb-4'>Our Customer Reservoir</h2>
										<p className='our-customers-paragraph'>
											Our clients are business-to-business (B2B) and (B2C)
											Manufacturing and professional services companies who fall
											into three primary categories of business size, focus and
											market position:
										</p>
										<p className='our-customers-paragraph'>
											<span className='sub-heading'>Startup Companies -</span>
											<span>
												These firms are generally under 10m in annual revenue,
												are focused on establishing a new foothold in the
												market, and are 0-3 years old
											</span>
										</p>
										<p className='our-customers-paragraph'>
											<span className='sub-heading'>Growth Companies -</span>
											<span>
												These firms are generally 10m to 1b in annual revenue
												and are focused on building and maintaining growth in
												their market
											</span>
										</p>
										<p className='our-customers-paragraph'>
											<span className='sub-heading'>
												Middle-Market Companies -
											</span>
											<span>
												These firms are generally 1b to 5b+ in annual revenue
												and are focused on maintaining their existing position,
												expanding into new segments, or preparing for future
												transitions (mergers, acquisitions, organic growth in
												new markets, leadership transition, owner exit, etc.).
											</span>
										</p>
									</div>
								</div>
							) : (
								''
							)}
						</div>
					</InfoWrapper>
				</InfoContainer>
			</div>
		</>
	);
};

export default InfoSectionLight;
