import styled from 'styled-components';

export const ServicesContainer = styled.div`
    padding-top: 7rem;

    @media screen and (max-width: 768px) {
        padding-top: 3rem;
    }

    @media screen and (max-width: 480px) {
        padding-top: 3rem;
    }
`;

export const ServicesWrapper = styled.div`
    grid-gap: 16px;
    padding: 20px 76px;

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
        padding: 20px 40px;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        //padding: 0 20px;
        padding: 20px 40px;
    }
`;

export const ServicesCard = styled.div`
    background: white;

    border: 1px solid #fec10e;
    border-radius: 10px;
    min-height: 200px;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;
`;

export const ServicesIcon = styled.img`
    height: 160px;
    width: 160px;
    margin-bottom: 40px;
`;

export const ServicesH1 = styled.h1`
    font-weight: 900;
    font-size: 36px;
    line-height: 49px;
    color: #01768a;
    text-align: center;
    @media screen and (max-width: 480px) {
        font-size: 2rem;
    }
`;

export const ServicesH2 = styled.h2`
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 900;
    text-transform: uppercase;
    color: #01768a;
`;

export const ServicesP = styled.p`
    font-size: 1rem;
    font-weight: 400;
`;