import React from 'react';
import {
    ServicesContainer,
    ServicesH1,
    ServicesWrapper,
    ServicesCard,
    ServicesH2,
    ServicesP,
} from './ServicesElements';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Services = () => {
    return (
        <ServicesContainer id='services'>
            <ServicesH1>Testimonials</ServicesH1>
            <ServicesWrapper>
                <OwlCarousel
                    className='owl-theme'
                    loop
                    margin={10}
                    nav={false}
                    autoplay={true}
                    dots={false}
                    items={3}
                    responsiveClass={true}
                >
                    <ServicesCard>
                        <ServicesH2>BW BusinessWorld</ServicesH2>
                        <ServicesP>
                            LIGs Are The Next Big Growth Driver For India’s Real Estate Sector
                        </ServicesP>
                        <div className='view-more-div'>
                            <a
                                className='view-more-link'
                                href='http://www.businessworld.in/article/LIGs-Are-The-Next-Big-Growth-Driver-For-India-s-Real-Estate-Sector/01-07-2019-172604/'
                                target={'_blank'}
                                rel='noopener noreferrer'
                            >
                                VIEW MORE
                            </a>
                        </div>
                    </ServicesCard>
                    <ServicesCard>
                        <ServicesH2>Entrepreneur India</ServicesH2>
                        <ServicesP>
                            Relevance of Second Generation Family Businesses in the Age of
                            Conglomerates and Disruptors
                        </ServicesP>
                        <div className='view-more-div'>
                            <a
                                className='view-more-link'
                                href='https://www.entrepreneur.com/article/338089'
                                rel='noopener noreferrer'
                                target={'_blank'}
                            >
                                VIEW MORE
                            </a>
                        </div>
                    </ServicesCard>
                    <ServicesCard>
                        <ServicesH2>99acres</ServicesH2>
                        <ServicesP>
                            The affordable housing success story for LIGs and EWS
                        </ServicesP>
                        <div className='view-more-div'>
                            <a
                                className='view-more-link'
                                href='https://www.99acres.com/articles/the-affordable-housing-success-story-for-ligs-and-ews.html'
                                rel='noopener noreferrer'
                                target={'_blank'}
                            >
                                VIEW MORE
                            </a>
                        </div>
                    </ServicesCard>
                    <ServicesCard>
                        <ServicesH2>Yourstory</ServicesH2>
                        <ServicesP>
                            How this CEO transformed a loss-making infrastructure materials
                            manufacturer into a Rs 150 Cr company
                        </ServicesP>
                        <div className='view-more-div'>
                            <a
                                className='view-more-link'
                                href='https://yourstory.com/2020/01/infrastructure-materials-manufacturer-walplast/amp'
                                rel='noopener noreferrer'
                                target={'_blank'}
                            >
                                VIEW MORE
                            </a>
                        </div>
                    </ServicesCard>
                    <ServicesCard>
                        <ServicesH2>economictimes</ServicesH2>
                        <ServicesP>
                            How Walplast is turning to data intelligence for supply chain
                            forecasting
                        </ServicesP>
                        <div className='view-more-div'>
                            <a
                                className='view-more-link'
                                href='https://cio.economictimes.indiatimes.com/news/strategy-and-management/how-walplast-is-turning-to-data-intelligence-for-supply-chain-forecasting/76845851'
                                rel='noopener noreferrer'
                                target={'_blank'}
                            >
                                VIEW MORE
                            </a>
                        </div>
                    </ServicesCard>
                    <ServicesCard>
                        <ServicesH2>bbc</ServicesH2>
                        <ServicesP>
                            India’s economic woes may have only just begun
                        </ServicesP>
                        <div className='view-more-div'>
                            <a
                                className='view-more-link'
                                href='https://www.bbc.com/news/world-asia-india-53981830'
                                rel='noopener noreferrer'
                                target={'_blank'}
                            >
                                VIEW MORE
                            </a>
                        </div>
                    </ServicesCard>
                </OwlCarousel>
            </ServicesWrapper>
        </ServicesContainer>
    );
};

export default Services;