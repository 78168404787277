import React, { useState } from 'react';
import InfoSection from '../components/infoSection';
import AboutSection from '../components/about';
import { Navbar } from './../components/navbar';
import { TopNavBar } from './../components/topNavBar';
import Image1 from '../images/design-notes.svg';
import Image2 from '../images/space.svg';
import Image3 from '../images/undraw-date.svg';
import InfoSectionLight from '../components/infoSectionLight';
import Footer from '../components/footer';
import OurValuePropositions from '../components/ourValuePropositions';
import Image4 from '../images/onboard.svg';
import ReachUs from '../components/reachUs/index';
import Services from '../components/services/index';

export const Home = () => {
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => {
		setIsOpen(!isOpen);
	};

	return (
		<>
			<TopNavBar />
			<Navbar toggle={toggle} />
			<InfoSection
				image={Image1}
				subtitle='Opusevolve, SME Focused'
				title='Business growth consulting firm'
				text='We work exclusively with CEO’s / MD’s/ Owners to improve business performance. Ready to drive new growth in your business?'
				btnText='Talk to our expert'
			/>
			<AboutSection
				image={Image3}
				id='about'
				title='About Us!'
				text='Opus Evolve is a strategy consultancy, committed to bringing unconventional yet pragmatic thinking together with our clients to deliver actionable strategies for real impact in today’s complex business landscape. Innovation has become a necessary ingredient for sustained success. Critical to unlocking opportunities is Opus’ ideal balance of strengths – specialized experience with broad executional capabilities – to help you optimize your portfolio of businesses, uncover industry insights to make investment decisions, find effective paths for strategic growth opportunities and make acquisitions more rewarding.'
			/>
			<OurValuePropositions id='proposition' />
			<InfoSectionLight
				image={Image2}
				id='solutions'
				title='Our Growth-led Solutions'
				text='Opusevolve solutions are specifically designed to meet the needs of CEOs looking to improve sales, marketing, strategy or leadership results to achieve a defined growth objective. Some of these objectives include:'
			/>
			<InfoSectionLight
				image={Image4}
				id='onboard'
				heading='When to Onboard Us?'
				subtitle='We Are a Good Fit for Your Business If...'
				isOnboard={true}
			/>
			<Services />
			<ReachUs id='contact' />
			<Footer />
		</>
	);
};
