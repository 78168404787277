import styled from 'styled-components';

export const InfoContainer = styled.div`
	width: 100%;
	min-height: 700px;
	justify-content: center;
	display: flex;
`;

export const InfoWrapper = styled.div`
	display: flex;
	padding: 80px 80px 0 80px;
	justify-content: center;
	align-items: center;
	@media screen and (max-width: 768px) {
		padding: 40px;
	}
`;

export const Title = styled.h1`
	font-weight: 900;
`;

export const Heading = styled.h1`
	font-weight: 900;
	font-size: 36px;
	line-height: 49px;
	color: #01768a;
`;

export const Subtitle = styled.h5`
	color: #66787b;
	font-size: 24px;
	line-height: 33px;
	font-weight: bold;
`;
