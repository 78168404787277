import React, { useState, useEffect } from 'react';
import {
	Nav,
	NavbarContainer,
	NavLogo,
	NavMenu,
	NavItem,
	NavLinks,
	HomeLogo,
} from './NavbarElements';
import './../../App.css';
import HomeIcon from '../../images/home-logo.svg';

export const Navbar = ({ toggle }) => {
	const [scrollNav, setScrollNav] = useState(false);
	const [activeMenu, setActiveMenu] = useState();
	const changeNav = () => {
		if (window.scrollY) {
			setScrollNav(true);
		} else {
			setScrollNav(false);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', changeNav);
	}, []);

	return (
		<>
			<Nav scrollNav={scrollNav}>
				<NavbarContainer>
					<NavLogo to='/'>
						<HomeLogo src={HomeIcon} />
					</NavLogo>
					<NavMenu>
						<NavItem>
							<NavLinks
								to='about'
								className={activeMenu === 'about' ? 'active' : ''}
								onClick={() => {
									setActiveMenu('about');
								}}
							>
								About Us
							</NavLinks>
						</NavItem>
						<NavItem>
							<NavLinks
								to='proposition'
								className={activeMenu === 'proposition' ? 'active' : ''}
								onClick={() => {
									setActiveMenu('proposition');
								}}
							>
								Our Value Proposition
							</NavLinks>
						</NavItem>
						<NavItem>
							<NavLinks
								to='solutions'
								className={activeMenu === 'solutions' ? 'active' : ''}
								onClick={() => {
									setActiveMenu('solutions');
								}}
							>
								Our Growth-led Solutions
							</NavLinks>
						</NavItem>
						<NavItem>
							<NavLinks
								to='onboard'
								className={activeMenu === 'onboard' ? 'active' : ''}
								onClick={() => {
									setActiveMenu('onboard');
								}}
							>
								When to Onboard Us
							</NavLinks>
						</NavItem>
						<NavItem>
							<NavLinks
								to='contact'
								className={activeMenu === 'contact' ? 'active' : ''}
								onClick={() => {
									setActiveMenu('contact');
								}}
							>
								Reach Us
							</NavLinks>
						</NavItem>
					</NavMenu>
				</NavbarContainer>
			</Nav>
		</>
	);
};
